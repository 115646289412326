import Vue from 'vue';
import VueRouter from 'vue-router';

import LoginWrapper from './Views/Wrapper/LoginWrapper.vue';
import Login from './Views/Login/Login.vue';
import EmailConfirmation from './Views/EmailConfirmation/EmailConfirmation.vue';

Vue.use(VueRouter);

export default {
    // base: '/login',
    mode: 'history',
    routes: [
        {
            path: '/',
            component: LoginWrapper,
            children: [
                {
                    path: '/login',
                    component: Login,
                },
                {
                    path: '/set_password/:hash',
                    component: EmailConfirmation,
                },
                {
                    path: '/email_confirmation/:hash',
                    component: EmailConfirmation,
                    props: {
                        recover: true,
                    },
                },
            ],
        },
    ],
};
