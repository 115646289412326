// import SupportLine from '../SupportLine/SupportLine.vue';
// import LanguageSwitcher from '@/components/base/LanguageSwitcher/LanguageSwitcher.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        // SupportLine,
        // LanguageSwitcher,
    },
    name: 'LoginLayout',
    data() {
        return {
            defaultProps: {
                children: 'children',
                label: 'text',
            },
            activeIndex: '1',
            activeIndex2: '1',
        };
    },
    computed: {
        ...mapGetters(['agglomirationName', 'configExtends']),
        getImage() {
            const src = 'layout.lk.logo';
            const alt = 'layout.lk.title';
            return { src, alt };
        },
        krasnodarLogo({ configExtends }) {
            return configExtends.includes('krasnodar/logo');
        },
    },
    methods: {
        getImgUrl(fileName) {
            // const images = require.context('../../../assets/images/', true, /\.svg$/);
            // return images(fileName);
        },
    },
};
