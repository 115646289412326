import Vue from 'vue';
import VueRouter from 'vue-router';
import singleSpaVue from 'single-spa-vue';
import App from './App.vue';
import routes from './router';

const router = new VueRouter(routes);

const vueLifecycles = singleSpaVue({
    Vue,
    async appOptions({ i18n, store }) {
        return {
            router,
            store,
            i18n,
            render: (r) => r(App),
            el: '#eputs-module-container',
        };
    },
});
export const bootstrap = [vueLifecycles.bootstrap];

export const mount = [vueLifecycles.mount];
// export function mount(props) {
//     // utils.registerProps(props, Vue);
//     // if (props.i18n) Vue.use(props.i18n); // setDefaultLocale(props.i18n);
//     if (props.checkPermission)
//     // return vueLifecycles.mount(props).then((instance) => {
//     //     Vue.use(modal, { router, i18n, store });
//     //     utils.initAccessRouteGate(instance, router);
//     // });
// }
export const unmount = [vueLifecycles.unmount];
