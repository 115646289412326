// export default {
//     name: 'Login',
// };
/* eslint-disable consistent-return */
import { mapActions } from 'vuex';
import { navigateToUrl } from 'single-spa';
import { AuthApi } from '@/api';
import { LOGIN } from '@/store/auth/auth-actions';
import 'url-search-params-polyfill';
import jwtDecode from 'jwt-decode';

export default {
    name: 'Login',
    data() {
        const validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('errors.required')));
            } else {
                if (this.setPassForm.checkPass !== '') {
                    this.$refs.setPassForm.validateField('checkPass');
                }
                callback();
            }
        };
        const validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('errors.required')));
            } else if (value !== this.setPassForm.pass) {
                callback(new Error(this.$t('errors.not_match_pass')));
            } else {
                callback();
            }
        };
        return {
            acceptRequest: false,
            loginLoading: false,
            forgotWindow: false,
            setPassForm: {
                pass: '',
                checkPass: '',
            },
            recoverForm: {
                login: '',
            },
            form: {
                login: '',
                password: '',
            },
            rules: {
                pass: [
                    { validator: validatePass, trigger: 'blur' },
                ],
                checkPass: [
                    { validator: validatePass2, trigger: 'blur' },
                ],
            },
        };
    },
    created() {
        // this.$store.dispatch('getConfig');
        localStorage.removeItem('sopt-user');
        localStorage.removeItem('token');
        localStorage.removeItem('tokenParsed');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('expires_in');
        localStorage.removeItem('agglomeration');

        this.checkTokenUrl();
    },
    methods: {
        ...mapActions({
            loginSuccess: LOGIN,
        }),
        inputHandler() {
            if (this.$refs.form) this.$refs.form.clearValidate();
        },
        submitForm() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loginLoading = true;
                    const params = new URLSearchParams();
                    params.append('grant_type', 'password');
                    params.append('client_id', 'login-app');
                    params.append('username', this.form.login);
                    params.append('password', this.form.password);
                    this.authorize({ ...this.form });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetPasswordHandler() {
            this.$refs.recoverForm.validate(this.submitRecoverForm);
        },
        async submitRecoverForm(valid) {
            if (!valid) {
                console.log('error submit!!');
                return;
            }
            try {
                this.loginLoading = true;
                const params = {
                    email: this.recoverForm.login,
                };
                const response = await AuthApi.resetPassword(params);
                if (response.status === 200) {
                // if (response.data.validation_result.success === true) {
                    this.acceptRequest = true;
                } else this.$message.error(`${this.$t('auth.error')}`);
                // else if (response.data.validation_result.common_errors) {
                //     const errors = response.data.validation_result.common_errors;
                //     if (Array.isArray(errors) && errors.length && errors[0]) {
                //         this.$message.error(`${errors[0]}. ${this.$t('auth.error')}`);
                //     }
                // }
            } catch (e) {
                console.error(e);
            } finally {
                this.loginLoading = false;
            }
        },
        submitSetPassForm() {
            this.$refs.setPassForm.validate((valid) => {
                this.loginLoading = true;
                if (valid) {
                    this.$message.success(this.$t('messages.password_success'));
                } else {
                    this.$message.error(this.$t('errors.password_save_error'));
                    console.log('error submit!!');
                    return false;
                }
                this.loginLoading = false;
            });
        },
        parseJwt(token) {
            return jwtDecode(token);
        },
        authorize(data) {
            AuthApi.login(data)
                .then((response) => {
                    const res = response.data;
                    this.authorizeSuccess(res);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        if (error.response.data.error === 'user_temporarily_disabled') this.authFail('auth.blocked');
                        else if (error.response.data.error === 'Unauthorized') this.authFail('users.message_block');
                        else this.authFail('auth.login_fail');
                    } else {
                        this.loginLoading = false;
                        console.error(error);
                        this.authFail('Ошибка авторизации');
                    }
                });
        },
        authorizeSuccess(data) {
            this.loginSuccess(data, false, true).then((answer) => {
                const tokenParsed = this.parseJwt(data.access_token);
                if (!tokenParsed) {
                    this.authFail('auth.error_permissions');
                    return;
                }
                this.$store.commit('tokenParsed', tokenParsed);
                localStorage.setItem('token', data.access_token);
                if (data.refresh_token) localStorage.setItem('refresh_token', data.refresh_token);
                localStorage.setItem('tokenParsed', JSON.stringify(tokenParsed));
                navigateToUrl('/main');
                // this.$router.push('/main');
            });
        },
        authFail(message) {
            this.loginLoading = false;
            this.$message.error(message ? this.$t(message) : this.$t('errors.base'));
        },
        checkTokenUrl() {
            if (!this.$route.query.token) return;
            this.authorizeSuccess({ access_token: this.$route.query.token });
        },
    },
};
