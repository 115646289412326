import {
    // confirmEmail,
    // resetEmail,
    // validateEmailHash,
    // setPassword,
    AuthApi,
} from '@/api';

export default {
    name: 'EmailConfirmation',
    props: {
        recover: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const validatePass = (rule, value, callback) => {
            const re = new RegExp(/^(?=.*[0-9])(?=.*[a-zA-Z])[0-9a-zA-Z!@#$%^&*]{8,}$/g);
            if (value === '') {
                callback(new Error(this.$t('errors.required')));
            } else if (!re.test(value)) {
                callback(new Error(this.$t('errors.password')));
            } else {
                if (this.setPassForm.checkPass !== '') {
                    this.$refs.setPassForm.validateField('checkPass');
                }
                callback();
            }
        };
        const validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('errors.required')));
            } else if (value !== this.setPassForm.pass) {
                callback(new Error(this.$t('errors.not_match_pass')));
            } else {
                callback();
            }
        };
        return {
            successView: false,
            estimateInterval: null,
            estimate: 30,
            errorEmailHash: false,
            setPassForm: {
                pass: '',
                checkPass: '',
            },
            rules: {
                pass: [
                    { required: true, message: 'errors.required', trigger: 'change' },
                    { validator: validatePass, trigger: 'change' },
                ],
                checkPass: [
                    { required: true, message: 'errors.required', trigger: 'change' },
                    { validator: validatePass2, trigger: 'change' },
                ],
            },
        };
    },
    methods: {
        startTimer() {
            this.estimateInterval = setInterval(() => {
                this.estimate -= 1;
                if (!this.estimate || this.estimate < 1) {
                    clearInterval(this.estimateInterval);
                    this.$router.push('/login');
                }
            }, 1000);
        },
        submitSetPassForm() {
            this.validateForm('setPassForm', this.submitSetPassFormAction);
        },
        async submitSetPassFormAction() {
            this.loading = true;
            try {
                const response = await AuthApi.setPassword(this.$route.params.hash, { password: this.setPassForm.pass });
                if (response.status === 200) {
                    this.successView = true;
                    this.startTimer();
                }
            } catch (e) {
                this.errorHandler(e, 'EmailConfirmation: submitSetPassFormAction', this.$t('errors.password_add_error'));
            } finally {
                this.loading = false;
            }
        },
    },
};
